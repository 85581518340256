import React from 'react';
import '../../styles/Modal.scss';
import './ThankYouModal.scss';

const ThankYouModal = (props) => {
	return (
		<div className='modal-background thank-you'>
			<div className='modal-container'>
				<div
					className='close-modal-button'
					aria-label='Close Thank You Modal'
					onClick={() => props.closeModal()}>
					&times;
				</div>
				<div className='thank-you-container'>
					<p className='header'>Thank You</p>
					<p>
						Your generosity contributes to the vision for our future home and unlocks a piece of the puzzle.
						Together, we will build a church that will transform our city and impact our world that extends
						far beyond our lifetime.
					</p>
					<p className='contribution-email'>A confirmation email has been sent to {props.email}.</p>
				</div>
			</div>
		</div>
	);
};

export default ThankYouModal;
