import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './App.scss';

import buildingIcon from './images/building_icon.png';
import donateIcon from './images/donate_icon.png';
import peopleChurchLogo from './images/people_church_logo.png';

import BuildingPage from './components/BuildingPage/BuildingPage';
import PaymentModal from './components/PaymentModal/PaymentModal';
import PixelPage from './components/PixelPage/PixelPage';
import ThankYouModal from './components/ThankYouModal/ThankYouModal';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY_TEST);

const App = () => {
	const [isPixelView, setPixelView] = useState(false);
	const [totalAmountDonated, setTotalAmountDonated] = useState(0);
	const [showThankYouModal, setThankYouModalVisibility] = useState(false);
	const [thankYouModalData, setThankYouModalData] = useState({});
	const [timeRemaining, setTimeRemaining] = useState(null);
	const [showDonationModal, setPaymentModalVisibility] = useState(false);
	const [donationModalData, setDonationModalData] = useState([]);

	const stripeAuthHeader = {
		'Content-Type': 'application/x-www-form-urlencoded',
		Authorization: `Bearer ${process.env.REACT_APP_STRIPE_API_KEY_SECRET}`,
	};

	const getTimeRemaining = () => {
		const endDateTime = new Date('mar 28, 2021 23:59:59').getTime();
		const nowDateTime = new Date().getTime();
		const time = endDateTime - nowDateTime;
		const days = Math.floor(time / 86400000);
		const hours = Math.floor((time % 86400000) / 3600000);
		const minutes = Math.floor((time % 3600000) / 60000);
		const seconds = Math.floor((time % 60000) / 1000);
		setTimeRemaining(`${days}D ${hours}H ${minutes}M ${seconds}S`);
	};

	const changeView = () => {
		setPixelView(!isPixelView);
		setPaymentModalVisibility(false);
	};

	const showThankYouView = (email) => {
		setPixelView(true);
		setPaymentModalVisibility(false);
		setThankYouModalData({ email });
		setThankYouModalVisibility(true);
	};

	const openPaymentModal = async () => {
		const stripePrices = await axios.get('https://api.stripe.com/v1/prices?product=prod_Hh9sWg6HdXSEzr', {
			headers: stripeAuthHeader,
		});
		setDonationModalData(stripePrices.data.data);
		setPaymentModalVisibility(!showDonationModal);
	};

	useEffect(() => {
		let totalAmountOfMoneyDonated = 0;

		const getAllStripeProducts = async () => {
			const stripeProducts = await axios.get('https://api.stripe.com/v1/products', { headers: stripeAuthHeader });
			const stripeProductsArr = stripeProducts.data.data.filter(
				(product) =>
					product.id !== 'prod_HekhvckeccFrGX' &&
					product.id !== 'prod_Heiqg66d7OQ8pk' &&
					product.id !== 'prod_HeiyzMmm7iIAqB'
			);

			stripeProductsArr.forEach(async (stripeProduct) => {
				if (stripeProduct.id !== 'price_1H6VCSAwyHSz9afnr5YUIPI7') {
					const stripePrices = await axios.get(
						`https://api.stripe.com/v1/prices?product=${stripeProduct.id}`,
						{
							headers: stripeAuthHeader,
						}
					);

					stripePrices.data.data.forEach((productPriceData) => {
						const totalAmountOfProduct = parseInt(productPriceData.metadata.amountTotal, 10);
						const totalAmountOfProductPurchased =
							totalAmountOfProduct - parseInt(productPriceData.metadata.amountAvailable, 10);

						totalAmountOfMoneyDonated +=
							(productPriceData.unit_amount / 100) * totalAmountOfProductPurchased;
						setTotalAmountDonated(totalAmountOfMoneyDonated);
					});
				}
			});
		};

		setInterval(() => getTimeRemaining(), 1000);
		getAllStripeProducts();
	}, []);

	return (
		<Elements stripe={stripePromise}>
			{isPixelView ? <PixelPage /> : <BuildingPage changeView={(email) => showThankYouView(email)} />}
			<div className='main-logo'>
				<a href={process.env.REACT_APP_PEOPLE_CHURCH_LINK} rel='noopener noreferrer' target='_blank'>
					<img src={peopleChurchLogo} alt='People Church logo'></img>
				</a>
			</div>
			<div className='countdown'>
				<span>
					<p>{timeRemaining ? timeRemaining : ''}</p>
				</span>
			</div>
			{isPixelView ? (
				<div>
					<div className='overlay-elements'>
						<div className='overlay-button pixel-overlay-button' onClick={changeView}>
							<img
								alt='Button to visualize contributions made to People Church'
								className='overlay-button-pixel-page-image icon'
								src={buildingIcon}
							/>
							<p className='overlay-button-pixel-page-text'>Contribute to our building</p>
						</div>
					</div>
				</div>
			) : (
				<div className='overlay-elements'>
					<div className='overlay-button right' onClick={changeView}>
						<img
							alt='Button to visualize contributions made to People Church'
							className='icon'
							src={buildingIcon}
						/>
						<p>See where your giving has contributed to the full picture</p>
					</div>
					<div className='overlay-button left' onClick={openPaymentModal}>
						<img
							alt='Button to donate to our new building for People Church'
							className='icon'
							src={donateIcon}
						/>
						<p>Give an amount without selecting a category</p>
					</div>
				</div>
			)}
			{showDonationModal && (
				<PaymentModal
					name='GIVE OUTSIDE A CATEGORY'
					imagePath='https://d1hbn7mydat7v7.cloudfront.net/all_images.gif'
					donationCategories={donationModalData.sort((a, b) => (a.unit_amount > b.unit_amount ? 1 : -1))}
					donationDescription='Your generosity extends far greater than a monetary amount and constructing
					 a building. Your generosity contributes to a movement and a legacy which will be experienced for 
					 generations to come, impacting Chicago and beyond. Below, you can contribute an amount outside of 
					 our six main categories, and these funds will be allocated towards any other building expenses.'
					closeModal={() => openPaymentModal()}
					confirmSuccessfulPayment={(email) => showThankYouView(email)}
				/>
			)}
			{showThankYouModal && (
				<ThankYouModal email={thankYouModalData.email} closeModal={() => setThankYouModalVisibility(false)} />
			)}
			<p className='progress-bar-percentage'>
				{totalAmountDonated ? `${Math.round((totalAmountDonated / 11000) * 100) / 100}% RAISED` : ''}
			</p>
			<div className='root-progress-bar'>
				<div
					className='root-progress-bar-inner'
					style={{
						width: `${
							(totalAmountDonated / 1100000) * 100 > 100 ? 100 : (totalAmountDonated / 1100000) * 100
						}%`,
					}}></div>
			</div>
		</Elements>
	);
};

export default App;
