import React, { useEffect, useState } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import './BuildingPage.scss';
import AnimatedImage from '../AnimatedImage/AnimatedImage';
import PaymentModal from '../PaymentModal/PaymentModal';
import data from '../../data/building-data.json';
import axios from 'axios';
import { get } from 'lodash';

const BuildingPage = (props) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [stripeProducts, setStripeProducts] = useState([]);
	const [modalName, setModalName] = useState(null);
	const [modalDonationDescription, setModalDonationDescription] = useState(null);
	const [modalImageUrl, setModalImageUrl] = useState(null);
	const [modalCategories, setModalCategories] = useState([]);

	const stripeAuthHeader = {
		'Content-Type': 'application/x-www-form-urlencoded',
		Authorization: `Bearer ${process.env.REACT_APP_STRIPE_API_KEY_SECRET}`,
	};

	const getModalImageUrl = (categoryName) => {
		if (categoryName === 'Construction & Structural')
			return `https://d1hbn7mydat7v7.cloudfront.net/structural/mood_board.jpg`;
		if (categoryName === 'Fixtures & Furnishings')
			return `https://d1hbn7mydat7v7.cloudfront.net/fixtures/mood_board.jpg`;
		return `https://d1hbn7mydat7v7.cloudfront.net/${categoryName.toLowerCase()}/mood_board.jpg`;
	};

	const getAnimatedImageUrl = (categoryName) => {
		if (categoryName === 'Construction & Structural')
			return `https://d1hbn7mydat7v7.cloudfront.net/structural/main-square.jpg`;
		if (categoryName === 'Fixtures & Furnishings')
			return `https://d1hbn7mydat7v7.cloudfront.net/fixtures/main-square.jpg`;
		return `https://d1hbn7mydat7v7.cloudfront.net/${categoryName.toLowerCase()}/main-square.jpg`;
	};

	const showSingleModal = async (id, donationName, donationMetadata) => {
		const stripePrices = await getPricesByStripeProductId(id);

		setModalImageUrl(getModalImageUrl(donationName));
		setModalName(donationName);
		setModalCategories(stripePrices.sort((a, b) => (a.unit_amount > b.unit_amount ? 1 : -1)));
		setModalDonationDescription(donationMetadata.donationDescription);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
		setModalImageUrl(null);
		setModalName(null);
		setModalCategories(null);
		setModalDonationDescription(null);
	};

	const confirmSuccessfulPayment = (email) => {
		closeModal();
		props.changeView(email);
	};

	const getPricesByStripeProductId = async (productId) => {
		const stripePrices = await axios.get(`https://api.stripe.com/v1/prices?product=${productId}`, {
			headers: stripeAuthHeader,
		});

		return get(stripePrices, ['data', 'data'], null);
	};

	useEffect(() => {
		const getAllStripeProducts = async () => {
			const stripeProducts = await axios.get('https://api.stripe.com/v1/products', { headers: stripeAuthHeader });
			const filteredStripeProducts = stripeProducts.data.data.filter(
				(product) =>
					product.id !== 'prod_HekhvckeccFrGX' &&
					product.id !== 'prod_Heiqg66d7OQ8pk' &&
					product.id !== 'prod_HeiyzMmm7iIAqB' &&
					product.id !== 'prod_Hh9sWg6HdXSEzr'
			);

			setStripeProducts(filteredStripeProducts);
		};

		getAllStripeProducts();
	}, []);

	return (
		<div>
			<Jumbotron fluid className='jumbotron-building'>
				<div className='animated-images-container'>
					{stripeProducts.map((buildingData, index) => (
						<AnimatedImage
							key={index}
							name={buildingData.name}
							openModal={() => showSingleModal(buildingData.id, buildingData.name, buildingData.metadata)}
							cssClassName={buildingData.metadata.cssClassName}
							numberOfRows={buildingData.metadata.numberOfRows}
							numberOfColumns={buildingData.metadata.numberOfColumns}
							tileWidth={buildingData.metadata.tileWidth}
							donationMatrix={
								data.find((image) => image.cssClassName === buildingData.metadata.cssClassName)
									.donationMatrix
							}
							animatedImageUrl={getAnimatedImageUrl(buildingData.name)}
							donationDescription={buildingData.metadata.donationDescription}
						/>
					))}
				</div>
				{modalOpen ? (
					<PaymentModal
						name={modalName}
						imagePath={modalImageUrl}
						donationCategories={modalCategories}
						donationDescription={modalDonationDescription}
						closeModal={() => closeModal()}
						confirmSuccessfulPayment={(email) => confirmSuccessfulPayment(email)}
					/>
				) : (
					''
				)}
			</Jumbotron>
		</div>
	);
};

export default BuildingPage;
