import React, { useEffect, useState } from 'react';
import './ProgressBar.scss';

const ProgressBar = (props) => {
	const [width, setWidth] = useState(0);
	const getPercentageFilled = (donationCategories) => {
		let totalAmountPurchased = 0;
		let totalAmount = 0;
		donationCategories.forEach((donationCategory) => {
			totalAmountPurchased +=
				((donationCategory.metadata.amountTotal - donationCategory.metadata.amountAvailable) *
					donationCategory.unit_amount) /
				100;
			totalAmount += (donationCategory.metadata.amountTotal * donationCategory.unit_amount) / 100;
		});
		return (totalAmountPurchased / totalAmount) * 100;
	};

	useEffect(() => {
		setWidth(getPercentageFilled(props.donationCategories));
	}, [props.donationCategories]);

	return (
		<div className='progress-bar-container'>
			<div className='bar'>
				<div className='bar-filled' style={{ width: `${width}%` }}></div>
			</div>
			<div className='percentage'>
				<p>
					{Math.ceil(getPercentageFilled(props.donationCategories) * 100) / 100}% raised for {props.name}
				</p>
			</div>
		</div>
	);
};

export default ProgressBar;
