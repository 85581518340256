import React, { useCallback, useEffect } from 'react';
import { Sine, TweenMax, TimelineMax } from 'gsap';
import PulsatingCircle from '../PulsatingCircle/PulsatingCircle';
import './AnimatedImage.scss';

const AnimatedImage = (props) => {
	const animatedImageUrl = props.animatedImageUrl;
	const cssClassName = props.cssClassName;
	const numberOfRows = parseInt(props.numberOfRows, 10);
	const rowArr = new Array(numberOfRows).fill();
	const numberOfColumns = parseInt(props.numberOfColumns, 10);
	const colArr = new Array(numberOfColumns).fill();
	const tileWidth = props.tileWidth;
	const random = (min, max) => Math.random() * (max - min + 1) + min;

	const animateTiles = useCallback((tiles) => {
		const timelines = [];
		for (let i = 0; i < tiles.length; i++) {
			const row = Math.floor(i / numberOfColumns);
			const col = i % numberOfColumns;
			const variance = 0.2;
			let maxOpacity;
			try {
				maxOpacity = props.donationMatrix[row][col];
			} catch (e) {
				maxOpacity = 0.8;
			}

			const minOpacity = Math.max(0, maxOpacity - variance);

			const duration = random(1.0, 2.0);
			if (maxOpacity === 0) {
				TweenMax.set(tiles[i], { opacity: 0 });
				continue;
			}
			const tween = TweenMax.fromTo(
				tiles[i],
				duration,
				{
					opacity: minOpacity,
				},
				{
					opacity: maxOpacity,
					ease: Sine.easeInOut,
				}
			);
			timelines[i] = new TimelineMax({ repeat: -1, yoyo: true });
			timelines[i].add(tween);
			const start = Math.random() * duration;
			timelines[i].seek(start);
		}
	}, []);

	useEffect(() => {
		animateTiles(document.querySelectorAll(`.${cssClassName}-tile`));
	}, []);

	return (
		<div>
			<div className={cssClassName}>
				<div className='pulsating-circle-container'>
					<div className='pulsating-circle-container-inner' onClick={() => props.openModal()}>
						<PulsatingCircle />
					</div>
				</div>
				<div className={cssClassName + '-tiles'}>
					{rowArr.map((row, rowIndex) =>
						colArr.map((col, colIndex) => (
							<div
								key={rowIndex + colIndex}
								className={cssClassName + '-tile'}
								id={rowIndex}
								style={{
									backgroundImage: `url(${animatedImageUrl})`,
									backgroundPosition:
										-colIndex * parseInt(tileWidth, 10) +
										'px ' +
										-rowIndex * parseInt(tileWidth, 10) +
										'px',
								}}></div>
						))
					)}
				</div>
			</div>
			<div className={cssClassName + '-responsive image-button'} onClick={() => props.openModal()}>
				<p>{props.name}</p>
			</div>
		</div>
	);
};

export default AnimatedImage;
