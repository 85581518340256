import React, { useState } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import DonationTable from '../DonationTable/DonationTable';
import './PixelPage.scss';
const PixelPage = () => {
	const [tableIsRendered, setTableIsRendered] = useState(false);

	return (
		<Jumbotron fluid className={tableIsRendered ? 'jumbotron-pixel' : 'jumbotron-pixel-hidden'}>
			<div className='generosity-container'>
				<h3 className='generosity-header'>YOUR GENEROSITY UNLOCKS A PIECE OF THE PUZZLE</h3>
			</div>
			<DonationTable isRendered={() => setTableIsRendered(true)} />
		</Jumbotron>
	);
};

export default PixelPage;
