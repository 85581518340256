import React, { useState, useEffect } from 'react';
import './PaymentModal.scss';
import '../../styles/Modal.scss';
import ProgressBar from '../ProgressBar/ProgressBar';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import qs from 'qs';
import { get } from 'lodash';

const PaymentModal = (props) => {
	const stripeAuthHeader = {
		'Content-Type': 'application/x-www-form-urlencoded',
		Authorization: `Bearer ${process.env.REACT_APP_STRIPE_API_KEY_SECRET}`,
	};

	const [customerEmail, setCustomerEmail] = useState('');
	const [customerFirstName, setCustomerFirstName] = useState('');
	const [customerLastName, setCustomerLastName] = useState('');
	const [isCheckingOut, setIsCheckingOut] = useState(false);
	const [donationTotalItems, setDonationTotalItems] = useState([]);
	const [donationTotal, setDonationTotal] = useState(0);
	const [errorMessage, setErrorMessage] = useState(null);
	const [paymentIsProcessing, setPaymentIsProcessing] = useState(false);

	const amountWithCommas = (amount) => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	const getPriceForProduct = (unitAmount, amountTotal) => {
		return amountTotal / (unitAmount / 100);
	};

	const updateDonationTotal = (e, categoryIndex) => {
		const amountOfProduct = getPriceForProduct(props.donationCategories[categoryIndex].unit_amount, e.target.value);
		const thisDonationItem = donationTotalItems.find(
			(donationTotalItem) => donationTotalItem.priceId === props.donationCategories[categoryIndex].id
		);
		thisDonationItem.amountInCart = amountOfProduct;
		let totalDonationAmount = 0;
		donationTotalItems.forEach(
			(donationCategory, index) =>
				(totalDonationAmount += parseInt(document.getElementById(`category=${index}`).value, 10))
		);
		setDonationTotal(totalDonationAmount);
	};

	const stripe = useStripe();
	const elements = useElements();

	const handleEmailChange = (e) => {
		setCustomerEmail(e.target.value);
	};

	const handleFirstNameChange = (e) => {
		setCustomerFirstName(e.target.value);
	};

	const handleCustomerLastNameChange = (e) => {
		setCustomerLastName(e.target.value);
	};

	const handlePaymentInformationChange = ({ error }) => {
		error ? setErrorMessage(error.message) : setErrorMessage(null);
	};

	const findOrCreateCustomer = async (customerFullName) => {
		try {
			const customersRes = await axios.get('https://api.stripe.com/v1/customers', {
				headers: stripeAuthHeader,
			});

			const customers = get(customersRes, ['data', 'data'], [])
				.map((customer) => customer.name)
				.filter((customerName) => customerName === customerFullName);

			if (customers.length === 0) {
				const customerName = qs.stringify({
					name: customerFullName,
				});
				await axios.post('https://api.stripe.com/v1/customers', customerName, {
					headers: stripeAuthHeader,
				});
			}
		} catch (e) {
			return;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setPaymentIsProcessing(true);

		const donationData = donationTotalItems
			.filter((donationItem) => donationItem.amountInCart > 0)
			.map((donationItem) => ({
				category: props.name,
				categoryItemName: donationItem.name,
				priceId: donationItem.priceId,
				amountPurchased: donationItem.amountInCart,
				itemPrice: donationItem.stripeUnitAmount,
			}));

		let descriptionString = '';
		donationData.forEach((donationItem, index) => {
			return (descriptionString += `${donationItem.amountPurchased} ${donationItem.categoryItemName} ${
				donationData[index + 1] ? ' , ' : ''
			}`);
		});

		const paymentIntentBody = qs.stringify({
			amount: donationTotal * 100,
			currency: 'usd',
			payment_method_types: ['card'],
			description: descriptionString,
			receipt_email: customerEmail,
		});

		let paymentIntentReq;
		try {
			paymentIntentReq = await axios.post('https://api.stripe.com/v1/payment_intents', paymentIntentBody, {
				headers: stripeAuthHeader,
			});
		} catch (e) {
			setErrorMessage('Invalid payment submission.');
			setPaymentIsProcessing(false);
			return;
		}

		const clientSecret = get(paymentIntentReq, ['data', 'client_secret'], null);
		const cardElement = elements.getElement(CardElement);

		// eslint-disable-next-line no-unused-vars
		const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
			payment_method: {
				card: cardElement,
				billing_details: {
					name: customerFirstName + ' ' + customerLastName,
				},
			},
		});

		if (error) {
			setErrorMessage(error.message);
			setPaymentIsProcessing(false);
		} else {
			updateProductsPurchased();
			setPaymentIsProcessing(false);
			await findOrCreateCustomer(customerFirstName + ' ' + customerLastName);
			props.confirmSuccessfulPayment(customerEmail);
		}
	};

	const updateProductsPurchased = async () => {
		const filteredDonationItems = donationTotalItems.filter(
			(donationTotalItem) => donationTotalItem.amountInCart > 0
		);

		filteredDonationItems.forEach(async (filteredDonationItem, index) => {
			const metadata = qs.stringify({
				metadata: {
					amountAvailable:
						filteredDonationItems[index].amountAvailable - filteredDonationItems[index].amountInCart,
				},
			});

			await axios.post(`https://api.stripe.com/v1/prices/${filteredDonationItems[index].priceId}`, metadata, {
				headers: stripeAuthHeader,
			});
		});
	};

	useEffect(() => {
		setDonationTotalItems(props.donationCategories);
		setDonationTotalItems(
			props.donationCategories.map((donationCategory) => ({
				name: donationCategory.metadata.name,
				priceId: donationCategory.id,
				amountInCart: 0,
				amountTotal: parseInt(donationCategory.metadata.amountTotal, 10),
				amountAvailable: parseInt(donationCategory.metadata.amountAvailable, 10),
				stripeUnitAmount: donationCategory.unit_amount,
			}))
		);
	}, [props.donationCategories]);

	return (
		<div className='modal-background'>
			<div id='error-message'></div>
			<div
				className={
					props.name !== 'GIVE OUTSIDE A CATEGORY'
						? 'modal-container'
						: 'modal-container modal-container-give'
				}>
				<div className='payment-modal-left'>
					<h3 className='payment-modal-header'>{props.name}</h3>
					<img alt='Mood board of donation category' src={props.imagePath} className='payment-image'></img>
					{props.name !== 'GIVE OUTSIDE A CATEGORY' ? (
						<ProgressBar name={props.name} donationCategories={props.donationCategories} />
					) : (
						''
					)}
				</div>

				<div>
					<div className='payment-modal-right'>
						<div
							className='close-modal-button'
							aria-label='Close Contribution Modal'
							onClick={() => props.closeModal()}
							disabled={paymentIsProcessing}>
							&times;
						</div>
						{!isCheckingOut ? (
							<div>
								{donationTotalItems.map((donationCategory, index) => (
									<div className='donation-line-item' key={index}>
										<div className='donation-line-title'>
											<h3>
												{props.name !== 'GIVE OUTSIDE A CATEGORY' ? donationCategory.name : ''}
											</h3>
											<p
												className={
													props.name !== 'GIVE OUTSIDE A CATEGORY'
														? 'donation-line-price'
														: 'donation-line-price-give'
												}>
												${amountWithCommas(donationCategory.stripeUnitAmount / 100)}
												{props.name !== 'GIVE OUTSIDE A CATEGORY' ? (
													<i>
														{`(${
															donationCategory.amountTotal -
															donationCategory.amountAvailable
														}/${donationCategory.amountTotal} funded)`}
													</i>
												) : (
													''
												)}
											</p>
										</div>
										<div className='amount-selection'>
											<select
												onChange={(e) => updateDonationTotal(e, index)}
												id={'category=' + index}>
												{new Array(donationCategory.amountAvailable + 1)
													.fill()
													.map((amount, index) => (
														<option
															selected={donationCategory.amountInCart === index}
															key={index}
															value={(index * donationCategory.stripeUnitAmount) / 100}>
															{index}
														</option>
													))}
											</select>
										</div>
										<div className='contribute-button' id={'category-donation-amount=' + index}>
											{`$${amountWithCommas(
												donationCategory.amountInCart *
													(donationCategory.stripeUnitAmount / 100)
											)}.00`}
										</div>
									</div>
								))}
								<div className='payment-divider'></div>
								<div className='donation-line-item'>
									<div className='payment-total'>
										<h3 className='payment-total-text'>TOTAL:</h3>
										<h3 className='payment-total-amount'>${amountWithCommas(donationTotal)}.00</h3>
									</div>
									<div className='payment-contribute-button'>
										<button
											onClick={() => {
												setIsCheckingOut(true);
												setErrorMessage('');
											}}
											disabled={donationTotal <= 0}>
											CONTRIBUTE
										</button>
									</div>
								</div>
							</div>
						) : (
							<div className='payment-form'>
								<div className='payment-description'>{props.donationDescription}</div>
								<form onSubmit={handleSubmit}>
									<div className='payment-name-inputs'>
										<div className='payment-name-left'>
											<div className='payment-form-header'>First Name</div>
											<input
												required='true'
												className='payment-input'
												type='text'
												placeholder='First Name'
												value={customerFirstName}
												onChange={handleFirstNameChange}></input>
										</div>
										<div className='payment-name-right'>
											<div className='payment-form-header'>Last Name</div>
											<input
												required='true'
												className='payment-input'
												type='text'
												placeholder='Last Name'
												value={customerLastName}
												onChange={handleCustomerLastNameChange}></input>
										</div>
									</div>
									<div className='payment-form-header'>Email</div>
									<input
										required='true'
										className='payment-input'
										type='text'
										placeholder='Email'
										value={customerEmail}
										onChange={handleEmailChange}></input>
									<div className='payment-form-header'>Card Details</div>
									<CardElement
										className='stripe-payment-card'
										onChange={handlePaymentInformationChange}
									/>
									<div className='error-message'>{errorMessage ? errorMessage : ''}</div>
									<div className='payment-total'>
										<h3 className='payment-total-text'>TOTAL:</h3>
										<h3 className='payment-total-amount'>${amountWithCommas(donationTotal)}.00</h3>
									</div>
									<button
										type='submit'
										disabled={!stripe || paymentIsProcessing || customerEmail === ''}
										className='payment-submit'>
										{paymentIsProcessing ? 'LOADING...' : 'GIVE'}
									</button>
									<button
										disabled={paymentIsProcessing}
										className='payment-back'
										onClick={() => setIsCheckingOut(false)}>
										BACK
									</button>
								</form>
							</div>
						)}
					</div>
					<div className='donation-line-item'></div>
				</div>
			</div>
		</div>
	);
};

export default PaymentModal;
