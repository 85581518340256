import React, { useCallback, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import './DonationTable.scss';
import axios from 'axios';
import { get } from 'lodash';

let columnDonations = [];

const DonationTable = (props) => {
	const [totalAmountContributed, setTotalAmountContributed] = useState(0);
	const [totalDonationsMade, setTotalDonationsMade] = useState(0);
	const stripeAuthHeader = {
		'Content-Type': 'application/x-www-form-urlencoded',
		Authorization: `Bearer ${process.env.REACT_APP_STRIPE_API_KEY_SECRET}`,
	};

	const amountWithCommas = (amount) => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	const getAmountNeededTotal = (amountNeeded) => {
		return amountNeeded < 0 ? 0 : amountWithCommas(amountNeeded);
	};

	const getCustomers = async (startingAfter) => {
		const params = {
			limit: 100,
			...(startingAfter !== null && { starting_after: startingAfter }),
		};

		const customersRes = await axios.get('https://api.stripe.com/v1/customers', {
			params,
			headers: stripeAuthHeader,
		});

		return customersRes;
	};

	const getAmountOfGivers = async () => {
		let startingAfter = null;
		let hasMore = true;
		let amountOfGivers = 0;

		while (hasMore) {
			const customersRes = await getCustomers(startingAfter);
			hasMore = get(customersRes, ['data', 'has_more'], false);
			startingAfter = get(customersRes, ['data', 'data'], []).slice(-1)[0].id;
			amountOfGivers += get(customersRes, ['data', 'data'], []).length;
			setTotalDonationsMade(amountOfGivers);
			if (hasMore === false) break;
		}
	};

	const shuffle = (arr) => {
		arr.sort(() => Math.random() - 0.5);
		arr.sort(() => Math.random() - 0.5);
		arr.sort(() => Math.random() - 0.5);
	};

	const getAllStripeProducts = useCallback(async () => {
		let totalAmountDonated = 0;
		const stripeProducts = await axios.get('https://api.stripe.com/v1/products', { headers: stripeAuthHeader });
		const stripeProductsArr = stripeProducts.data.data.filter(
			(product) =>
				product.id !== 'prod_HekhvckeccFrGX' &&
				product.id !== 'prod_Heiqg66d7OQ8pk' &&
				product.id !== 'prod_HeiyzMmm7iIAqB'
		);

		for (const stripeProduct of stripeProductsArr) {
			const stripePrices = await axios.get(`https://api.stripe.com/v1/prices?product=${stripeProduct.id}`, {
				headers: stripeAuthHeader,
			});
			stripePrices.data.data.forEach((productPriceData) => {
				const totalAmountOfProduct = parseInt(productPriceData.metadata.amountTotal, 10);
				const totalAmountOfProductPurchased =
					totalAmountOfProduct - parseInt(productPriceData.metadata.amountAvailable, 10);
				totalAmountDonated += (productPriceData.unit_amount / 100) * totalAmountOfProductPurchased;
			});
		}
		setTotalAmountContributed(totalAmountDonated);
		const totalAmountOfMoneyDonatedInPixels = totalAmountDonated / 110;
		if (isNaN(totalAmountOfMoneyDonatedInPixels)) return;

		getAmountOfGivers();

		const amountOfDonationsArr = new Array(Math.round(totalAmountOfMoneyDonatedInPixels))
			.fill(undefined)
			.map((val, index) => ({ paid: true, index }));

		const allPossibleDonations = new Array(10000);
		for (let i = 0; i < allPossibleDonations.length; i++) {
			if (amountOfDonationsArr[i] !== undefined) {
				allPossibleDonations[i] = amountOfDonationsArr[i];
			} else {
				allPossibleDonations[i] = { paid: false, index: null };
			}
		}

		shuffle(allPossibleDonations);

		for (let i = 0; i < 9999; i += 99) {
			columnDonations.push(allPossibleDonations.slice(i, i + 99));
		}
		props.isRendered();
	});

	useEffect(() => {
		getAllStripeProducts();
	}, []);

	return (
		<div className='donation-table-container'>
			{totalDonationsMade !== 0 ? (
				<div className='amount-container'>
					<span>{<p>TOTAL AMOUNT OF GIVERS: {amountWithCommas(totalDonationsMade)} </p>}</span>
					<span>{<p>TOTAL AMOUNT CONTRIBUTED: ${amountWithCommas(totalAmountContributed)} </p>}</span>
					<span>
						{<p>TOTAL AMOUNT NEEDED: ${getAmountNeededTotal(1100000 - totalAmountContributed)} </p>}
					</span>
				</div>
			) : (
				''
			)}
			<Table>
				<tbody>
					{columnDonations.map((testing, index) => (
						<tr key={index}>
							{columnDonations[index].map((testing, index) => (
								<td key={index} className={testing.paid ? 'donation-made' : ''}></td>
							))}
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
};

export default DonationTable;
